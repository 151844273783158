// From Ahrefs guide here: https://ahrefs.com/blog/blog-post-templates/

<template>
  <div class="guide-container line-height-1-6">
    <h1 class="second-colour">4 Best Crypto Exchanges For Beginners To Buy Coins</h1>

    <img
      src="@/assets/imgs/Articles/cartoon-man-confused-about-crypto-currency.webp"
      alt="cartoon man confused about crypto currency"
    />

    <p>
      Feeling overwhelmed with the amount of information and where to start in
      the world of crypto? You're not alone. I was the same, and I'm still
      learning :)
    </p>

    <p>
      The easiest approach, I found, was to sign up to Binance, buy the minimum
      amount of Bitcoin and watch the price. It got my toe in the water and it
      was low risk for me.
    </p>
    <br />

    <!-- Disclaimer -->
    <hr class="line_separator" />
    <p class="fourth-colour">
      An obvious, but important disclaimer: Information here is for educational
      purposes only and is not financial advice. Do your own research before
      making any investments, as the value of cryptocurrencies can fluctuate.
      Play it safe my friends.
    </p>
    <p></p>
    <hr class="line_separator" />

    <p>
      Firstly, I recommend Binance since I've used that most. But this article
      covers a few of the other main exchanges for you to get started buying
      cryptocurrency.
    </p>

    <p>Let's get into it</p>

    <!-- ====================================================================== -->
    <!-- ====================================================================== -->
    <!-- Contents -->
    <!-- ====================================================================== -->
    <!-- ====================================================================== -->
    <ol>
      <li>
        <p>
          <a href="#list-item-1">Binance</a> - 4.1 / 5 ⭐
        </p>
      </li>
      <li>
        <p>
          <a href="#list-item-2">Coinbase</a> - 4.52 / 5 ⭐
        </p>
      </li>
      <li>
        <p>
          <a href="#list-item-3">Kraken</a> - 4.45 / 5 ⭐
        </p>
      </li>
      <li>
        <p>
          <a href="#list-item-4">Gemini</a> - 4.38 / 5 ⭐
        </p>
      </li>
    </ol>

    <br />

    <h2 class="second-colour">4 Best Crypto Exchange For Beginners</h2>

    <!-- ====================================================================== -->
    <!-- ====================================================================== -->
    <!-- Binance -->
    <!-- ====================================================================== -->
    <!-- ====================================================================== -->

    <h3
      class="second-colour-background padding-1 third-colour"
      id="list-item-1"
      @click="openBinanceRegisterPage()"
    >Binance</h3>

    <img
      src="@/assets/imgs/Companies/Binance/binance-market-overview-screen.webp"
      alt="binance market overview screen"
      @click="openBinanceRegisterPage()"
    />

    <h4 class="second-colour">Overall Rating - 4.1 / 5 ⭐</h4>

    <p>
      <a
        href="https://www.forbes.com/advisor/investing/cryptocurrency/binance-us-review/"
        target="_blank"
      >Forbes.com</a>

      - 3.8 / 5 ⭐
      <br />
      <a
        href="https://www.nerdwallet.com/reviews/investing/brokers/binance-us"
        target="_blank"
      >Nerdwallet.com</a>

      - 3.7 / 5 ⭐
      <br />
      <a
        href="https://www.fool.com/the-ascent/cryptocurrency/binance-review/"
        target="_blank"
      >Fool.com</a>

      - 3.5 / 5 ⭐
      <br />
      <a href="https://www.coinbureau.com/review/binance/" target="_blank">Coinbureau.com</a>

      - 4.5 / 5 ⭐
      <br />
      <a href="https://www.datawallet.com/crypto/binance-review" target="_blank">Datawallet.com</a>
      - 5 / 5 ⭐
    </p>

    <button class="primary-button" @click="openBinanceRegisterPage()">Go to Binance now</button>

    <br />
    <br />
    <br />

    <p>
      As I said, Binance is the crypto exchange I'm most familiar with and the
      first one I've used. The sign-up flow is fairly straight forward and it has
      a plenty of coins to choose from. Personally I just wanted to buy the
      minimum amount of bitcoin, the simplest way possible without needing to
      understand all the charts and lines.
    </p>

    <p>
      I purchased roughly $15 USD of bitcoin using GBP and then later just
      watched the price. Nice, simple, and low risk for me.
    </p>

    <p>
      You can see the Bitcoin (BTC) value in the screenshot below. Rich beyond
      my wildest dreams 😂
    </p>

    <img
      src="@/assets/imgs/Companies/Binance/binance-home-screen-showing-bitcoin-amount.webp"
      alt="binance home screen showing bitcoin amount"
    />

    <h4 class="second-colour">Pros</h4>

    <!-- Cons -->
    <ul>
      <li>Low fees compared to some of the other larger crypto exchanges.</li>
      <li>Plenty of crypto coins to choose from. And ways to earn passive income.</li>
      <li>Fairly easy to navigate and use for both beginners and pros.</li>
    </ul>

    <h4 class="second-colour">Cons</h4>

    <!-- Cons -->
    <ul>
      <li>
        Not available in the US. People based in the US will need to use the less
        featured Binance.US site instead of the global Binance site.
      </li>
      <li>Not beginner friendly.</li>
      <li>
        Has had regulatory issues. One specific example is no longer being able
        to process withdrawals and deposits for its UK users in GBP.
      </li>
    </ul>
    <br />

    <!-- Summary -->
    <h4 class="second-colour">Summary</h4>

    <p>
      For those of you with a sharp eye you'll have noticed that 'beginner
      friendly' is added to both Pros and Cons. There's a bit of mixed opinion
      when it comes to Binance. Personally I found using it no problem, however
      I need to check some of the other exchanges in more depth.
    </p>

    <p>
      If you decide to go down the Binance route you can check out my full
      guides here:
    </p>

    <ul>
      <li>
        <router-link
          to="/how-to-open-a-binance-account"
          target="_blank"
        >How to open a Binance account</router-link>
      </li>
      <li>
        <router-link to="/how-to-buy-bitcoin-on-binance-with-bank-transfer" target="_blank">
          How To Buy Bitcoin On
          Binance With Bank Transfer
        </router-link>
      </li>
    </ul>

    <br />
    <br />

    <!-- ====================================================================== -->
    <!-- ====================================================================== -->
    <!-- Coinbase -->
    <!-- ====================================================================== -->
    <!-- ====================================================================== -->

    <h3
      class="second-colour-background padding-1 third-colour"
      id="list-item-2"
      @click="openCoinbaseRegisterPage()"
    >Coinbase</h3>

    <img
      src="@/assets/imgs/Companies/Coinbase/coinbase-main-buy-sell-screen.webp"
      alt="coinbase main buy sell screen"
      @click="openCoinbaseRegisterPage()"
    />

    <br />
    <h4 class="second-colour">Overall Rating - 4.52 / 5 ⭐</h4>

    <!-- Other site ratings -->
    <p>
      <a
        href="https://www.nerdwallet.com/reviews/investing/brokers/coinbase"
        target="_blank"
      >nerdwallet.com</a>

      - 5 / 5 ⭐
      <br />
      <a
        href="https://www.investopedia.com/tech/coinbase-what-it-and-how-do-you-use-it/"
        target="_blank"
      >Investopedia.com</a>

      - 4.8 / 5 ⭐
      <br />
      <a
        href="https://www.forbes.com/advisor/investing/cryptocurrency/coinbase-review/"
        target="_blank"
      >Forbes.com</a>

      - 4.1 / 5 ⭐
      <br />
      <a
        href="https://www.fool.com/the-ascent/cryptocurrency/coinbase-review/"
        target="_blank"
      >Fool.com</a>

      - 4.5 / 5 ⭐
      <br />
      <a
        href="https://www.cryptovantage.com/best-crypto-exchanges/coinbase/"
        target="_blank"
      >Cryptovantage.com</a>
      - 4.2 / 5 ⭐
    </p>

    <button class="primary-button" @click="openCoinbaseRegisterPage()">Go to Coinbase now</button>

    <br />
    <br />
    <br />

    <img
      src="@/assets/imgs/Companies/Coinbase/coinbase-advanced-trading-chart.webp"
      alt="coinbase advanced trading chart"
    />

    <p>
      I've heard a lot about Coinbase and seen it's interface but I've not
      personally used it. However from reading a bunch of articles about the
      best exchanges, Coinbase appears over and over again.
    </p>

    <p>In general it has good reviews across the board. Here's some specifics:</p>

    <h4 class="second-colour">Pros</h4>

    <!-- Pros -->
    <ul>
      <li>
        Good user interface. This one for me is always crucial. I hate sites
        that have too many buttons, menus, and are generally just confusing.
      </li>
      <li>
        Good security features. Even more essential than the first point I'd
        say.
      </li>
      <li>Plenty of cryptocurrencies to choose from.</li>
    </ul>

    <h4 class="second-colour">Cons</h4>

    <!-- Cons -->
    <ul>
      <li>Higher fees than some of the other exchanges.</li>
      <li>Fee structure is complex.</li>
      <li>Customer service isn't amazing.</li>
      <li>And for our friends over the US, it's not available in all 50 states.</li>
    </ul>
    <br />

    <!-- Summary -->
    <h4 class="second-colour">Summary</h4>

    <p>
      Coinbase is definitely worth a check as it's one of the big boys and has
      plenty of features.
    </p>

    <p>
      I plan to do a full sign-up tutorial for Coinbase which I'll add here.
      I'll buy the minimum amount of Bitcoin or possibly Ethereum; putting my
      money where my mouth is, 💰 => 👄.
    </p>

    <br />
    <br />

    <!-- ====================================================================== -->
    <!-- ====================================================================== -->
    <!-- Kraken -->
    <!-- ====================================================================== -->
    <!-- ====================================================================== -->

    <h3
      class="second-colour-background padding-1 third-colour"
      id="list-item-3"
      @click="openKrakenRegisterPage()"
    >Kraken</h3>

    <img
      src="@/assets/imgs/Companies/Kraken/kraken-market-chart.webp"
      alt="kraken market chart"
      @click="openKrakenRegisterPage()"
    />

    <br />

    <h4 class="second-colour">Overall Rating - 4.45 / 5 ⭐</h4>

    <p>
      <a
        href="https://www.nerdwallet.com/reviews/investing/brokers/kraken"
        target="_blank"
      >Nerdwallet.com</a>

      - 4.3 / 5 ⭐
      <br />
      <a
        href="https://www.forbes.com/advisor/investing/cryptocurrency/kraken-review/"
        target="_blank"
      >Forbes.com</a>

      - 4.6 / 5 ⭐
      <br />
      <a href="https://www.investopedia.com/kraken-review-5208448" target="_blank">Money.com</a>

      - 4.2 / 5 ⭐
      <br />
      <a
        href="https://www.businessinsider.com/personal-finance/kraken-investing-review"
        target="_blank"
      >Businessinsider.com</a>

      - 4.35 / 5 ⭐
      <br />
      <a href="https://www.coinbureau.com/review/kraken/" target="_blank">Coinbureau.com</a>
      - 4.8 / 5 ⭐
    </p>

    <button class="primary-button" @click="openKrakenRegisterPage()">Go to Kraken now</button>

    <br />
    <br />
    <p>Gotta love the name. I think of Clash Of The Titans whenever I hear it.</p>

    <p>
      Again, this is one I've yet to get my hands on personally. But I see it
      appearing a lot when lurking through the crypto landscape. And from
      looking at the reviews it stands out as one to check out for sure.
    </p>

    <p>Let's have a quick break down based on some of the reviews I've seen:</p>

    <br />
    <h4 class="second-colour">Pros</h4>

    <ul>
      <li>Plenty of supported coins, much like the other platforms.</li>
      <li>Simple user interface. As I said before, this is great for beginners.</li>
      <li>
        Very active user base. Meaning that there's high liquidity to buy and
        sell. You don't want to get stuck with coins you can't sell.
      </li>
    </ul>

    <h4 class="second-colour">Cons</h4>

    <ul>
      <li>Can't be used in some US states.</li>
      <li>
        Only accepts a few fiat currencies for depositing and withdrawing.
        'Fiat' is normal currency like USD, GBP, EUR etc.
      </li>
      <li>There's higher fees if not using Kraken Pro.</li>
    </ul>

    <br />
    <br />

    <!-- ====================================================================== -->

    <!-- ====================================================================== -->
    <!-- ====================================================================== -->
    <!-- Gemini -->
    <!-- ====================================================================== -->
    <!-- ====================================================================== -->

    <h3
      class="second-colour-background padding-1 third-colour"
      id="list-item-4"
      @click="openGeminiRegisterPage()"
    >Gemini</h3>

    <img
      src="@/assets/imgs/Companies/Gemini/gemini-market-chart.webp"
      alt="kraken market chart"
      @click="openGeminiRegisterPage()"
    />

    <br />

    <h4 class="second-colour">Overall Rating - 4.38 / 5 ⭐</h4>

    <p>
      <a
        href="https://www.nerdwallet.com/reviews/investing/brokers/gemini"
        target="_blank"
      >Nerdwallet.com</a>

      - 4.2 / 5 ⭐
      <br />
      <a href="https://www.investopedia.com/gemini-review-5216840" target="_blank">Investopedia.com</a>

      - 4.6 / 5 ⭐
      <br />
      <a
        href="https://www.forbes.com/advisor/investing/cryptocurrency/gemini-review/"
        target="_blank"
      >Forbes.com</a>

      - 4.5 / 5 ⭐
      <br />
      <a
        href="https://www.businessinsider.com/personal-finance/gemini-review"
        target="_blank"
      >Businessinsider.com</a>

      - 4 / 5 ⭐
      <br />
      <a href="https://www.finder.com/gemini-exchange-review" target="_blank">Finder.com</a>
      - 4.6 / 5 ⭐
    </p>

    <button class="primary-button" @click="openGeminiRegisterPage()">Go to Gemini now</button>

    <br />
    <br />
    <p>
      Okey dokey. So Gemini is a fairly new one that I hadn't heard of before starting this article. But it appears
      over and over again with some fairly decent brownie points.
    </p>

    <p>Break it down for me!</p>

    <br />
    <h4 class="second-colour">Pros</h4>

    <ul>
      <li>All 50 US States can access Gemini, no need to travel across the country just to check your Gemini inbox.</li>
      <li>More than 100 cryptocurrencies to choose from. I know this has been repeated with others, but it's a must.</li>
      <li>Good mobile app experience. Super important for those first time users.</li>
    </ul>

    <h4 class="second-colour">Cons</h4>

    <ul>
      <li>Maybe a problem for some investors, maybe not, but it's missing some popular currencies. Not an issues for me personally at the time of writing.</li>
      <li>Trading fees can be high. However this depends on how often you're trading. Once a month would be low, several times a day and those fees will soon rack up.</li>
      <li>Word on the street is that their customer support isn't amazing. And when it comes to money, this is a must for me.</li>
    </ul>

    <br />

    <!-- ====================================================================== -->
    <!-- ====================================================================== -->
    <!-- Summary -->
    <!-- ====================================================================== -->
    <!-- ====================================================================== -->

    <h3 class="second-colour-background padding-1 third-colour">Summary</h3>

    <p>
      If you're just getting into crypto investing, Kraken isn't a bad choice
      either. In fact with any of these exchanges mentioned here you can't go
      too wrong based on the general consensus.
    </p>

    <p>
      However, always play it safe in my opinion. Try out each of the exchanges
      with the bare minimum amount so that you feel comfortable with the
      platform and it's features.
    </p>

    <p>
      Also consider spreading your crypto amongst several platforms to reduce
      the risk further. You can also withdraw crypto to your own crypto wallet.
      Some more on that here:
      <router-link to="/how-to-transfer-from-binance-to-another-wallet-address" target="_blank">
        How to transfer from
        binance to another wallet address
      </router-link>
    </p>

    <p>Happy investing all :)</p>

    <!-- ====================================================================== -->
  </div>
</template>

<script>
import { event } from "vue-gtag";

export default {
  setup() {
    const openBinanceRegisterPage = () => {
      //Track event in Google Analytics:
      //https://matteo-gabriele.gitbook.io/vue-gtag/
      event("best-crypto-exchanges", {
        method: "openBinanceRegisterPage"
      });

      //How to open link in a new tab:
      //https://javascript.plainenglish.io/vue-open-link-in-new-tab-6d4cbc009f0e
      window.open(
        "https://accounts.binance.com/en/register?ref=G6UDTO47",
        "_blank",
        "noreferrer"
      );
    };

    const openKrakenRegisterPage = () => {
      event("best-crypto-exchanges", {
        method: "openKrakenRegisterPage"
      });

      window.open(
        "https://www.kraken.com/en-us/sign-up?",
        "_blank",
        "noreferrer"
      );
    };

    const openCoinbaseRegisterPage = () => {
      event("best-crypto-exchanges", {
        method: "openCoinbaseRegisterPage"
      });

      window.open("https://www.coinbase.com/signup", "_blank", "noreferrer");
    };

    const openGeminiRegisterPage = () => {
      event("best-crypto-exchanges", {
        method: "openGeminiRegisterPage"
      });

      window.open("https://www.gemini.com/", "_blank", "noreferrer");
    };

    return {
      openBinanceRegisterPage,
      openCoinbaseRegisterPage,
      openKrakenRegisterPage,
      openGeminiRegisterPage
    };
  }
};
</script>
